<template>
        <b-tabs>
            <b-tab active title="Pembelian">
                <b-row class="my-2">
                    <b-col cols="12" md="6">
                        <table class="mt-2 mt-xl-0 w-100">
                            <tr>
                                <th class="pb-50">
                                    <feather-icon
                                        icon="CheckIcon"
                                        class="mr-75"
                                    />
                                    <span class="font-weight-bold">Supplier</span>
                                </th>
                                <td class="pb-50">
                                    : {{ purchase.supplier }}
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon
                                        icon="CheckIcon"
                                        class="mr-75"
                                    />
                                    <span class="font-weight-bold">Nomor Pembelian</span>
                                </th>
                                <td class="pb-50 text-capitalize">
                                    : {{ purchase.agreement_number }}
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon
                                        icon="CheckIcon"
                                        class="mr-75"
                                    />
                                    <span class="font-weight-bold">Tanggal Pembelian</span>
                                </th>
                                <td class="pb-50 text-capitalize">
                                    : {{ new Date(purchase.end_date).toString().slice(4,15) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon
                                        icon="CheckIcon"
                                        class="mr-75"
                                    />
                                    <span class="font-weight-bold">Harga</span>
                                </th>
                                <td class="pb-50 text-capitalize">
                                    : {{ purchase.price }}
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon
                                        icon="CheckIcon"
                                        class="mr-75"
                                    />
                                    <span class="font-weight-bold">Produk</span>
                                </th>
                                <td class="pb-50 text-capitalize">
                                    : {{ purchase.brand }} - {{ purchase.code }}
                                </td>
                            </tr>
                        </table>
                    </b-col>
                    <b-col cols="12" md="6">

                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Penyusutan" @click="fetchDataDepreciation()">
                <b-row class="my-2">
                    <b-col cols="12" md="8">
                        <depreciation :depreciationByDistribution="depreciationByDistribution"/>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Gambar">
                <b-row class="my-2">
                    <b-col cols="12" md="12">
                        <list-images :list_images="list_images"/>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>  
</template>

<script>
import { BTabs, BTab, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable, BFormCheckbox, BNavItem, BAvatar, 
    BCard, BAvatarGroup,
    BFormFile} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import purchaseApi from '@/store/purchase/index'
import { getUserData } from '@/auth/utils'
import ListImages from './ListImages.vue'
import Depreciation from './Depreciation.vue'

export default {
  props: {
    distributionId: {
      type: Object,
    },
    purchaseOrder: {
      type: Object,
    },
  },
  components: {
    BCardText,
    BTabs,
    BTab,
    BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable,
    BFormCheckbox,
    BNavItem,
    BAvatar,
    BAvatarGroup,
    BCard,
    BFormFile,
    ListImages,
    Depreciation
  },
  directives: {
    Ripple,
  },
  data() {
    return {
        purchase: {
            "agreement_number":"",
            "product_id":"",
            "product":"",
            "brand":"",
            "code":"",
            "description":"",
            "supplier":"",
            "quantity":"",
            "price":"",
            "discount":"",
            "end_date":""
        },
        list_images: '',
        depreciationByDistribution: ''
    }
  },
  methods: { 
    fetchDataPurchase() { 
        purchaseApi.fetchDataPurchase(this.purchaseOrder)
        .then(response => {
            this.purchase  = response[0]
        })
        .catch(error => {
          console.log(error)
        })
    },

    fetchImages() { 
        this.list_images = this.distributionId
    },
    
    fetchDataDepreciation() { 
        this.depreciationByDistribution = this.distributionId
    }
  },
  watch: {
    distributionId: {
      handler(newVal) {
        this.fetchImages()
        this.fetchDataDepreciation()  
      },
      immediate: true
    }
  }
}
</script>