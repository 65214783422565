<template>
  <b-row class="my-2">
      <b-col
          cols="12"
          md="6"
      >  
      <b-form @submit.prevent="updateExternal"> 
          <b-row class="my-2">
          <b-col cols="12" md="6">
              <div role="group"> 
              <label for="input-live">ID Produk</label>
                  <b-form-input
                  v-model="formAttribute.id_external"
                  id="mc-last-name"
                  />
              </div>
          </b-col>
          <b-col cols="12" md="6">
              <div role="group">
              <label for="input-live">Keterangan</label>
                  <b-form-input
                  type="text"
                  v-model="formAttribute.description"
                  />
              </div>
          </b-col>
          </b-row>
      </b-form>
          <div class="d-flex flex-column flex-sm-row pt-1">             
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
              @click="actionExternal"
          >
              <span>{{ $t('Apply') }}</span>
          </b-button>
          </div>
      </b-col>
      <b-col
          cols="12"
          md="6"
      >
          <b-card-text>
          {{ $t('Setting_unit') }} 
          </b-card-text>  
          <b-card-text>
          <b-table responsive="sm"></b-table>
          </b-card-text>    
      </b-col>      
  </b-row>
</template>

<script>
import { BTabs, BTab, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable, BFormCheckbox} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import { getUserData } from '@/auth/utils'

export default {
  props: {
    productId: {
      type: String,
      required: true
    },
  },
components: {
  BCardText,
  BTabs,
  BTab,
  BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable,
  BFormCheckbox,
},
directives: {
  Ripple,
},
data() {
  return {
    formAttribute: [
      { id: '', id_external: '', description: '' },
    ],
  }
},
methods: { 
  actionExternal() {
    const formData = {
      code: this.id_external, 
      description: this.description,
      product_id: this.productId,
    }
    if(formData.id === ''){
      formData.created_at = new Date().toISOString()
      moduleApi.insertExternal(formData)
      .then(response => {

      })
      .catch(error => {
        console.log(error)
      })
    } else {
      formData.id = this.id
      formData.updated_at = new Date().toISOString()
      moduleApi.updateExternal(formData)
      .then(response => {

      })
      .catch(error => {
        console.log(error)
      })
    }
    
  },   
  showMsgBoxRemove(id) {
    this.$bvModal
      .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
        title: 'Konfirmasi',
        size: 'sm',
        okVariant: 'danger',
        okTitle: 'Ya',
        cancelTitle: 'Tidak',
        cancelVariant: 'outline-secondary',
        hideHeaderClose: false,
        centered: true,
      })
      .then(value => {
        if(value){
          this.remove(id)
        }
      })
  },
  remove (id) {
    moduleApi.deletedDistribution({
      id: id,
      deleted_at: new Date().toISOString()
    })
    .then( response => {
      this.fetchDataListDistribution({warehouse: this.localStorageWarehouse.id, product: this.$route.params.id})
    })
    .catch( error => {
      console.log(error)
    })
  },
},
created () {
    
},
setup(props) {
 const { t } = useI18nUtils()
  return {
    t,
  }
},
}
</script>