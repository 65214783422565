<template>
    <b-col cols="12" md="12">
        <table class="mt-2 mt-xl-0 w-100">
            <tr>
                <th class="pb-50">
                    <feather-icon
                        icon="CheckIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Tahun Pengadaan</span>
                </th>
                <td class="pb-50">
                    <b-form-input
                        type="text"
                        v-model="formDepreciation.end_date"
                        />
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <feather-icon
                        icon="CheckIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Harga Perolehan</span>
                </th>
                <td class="pb-50 text-capitalize">
                    <b-form-input
                        type="text"
                        v-model="formDepreciation.price"
                        />
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <feather-icon
                        icon="CheckIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Nilai Residu</span>
                </th>
                <td class="pb-50 text-capitalize">
                    <b-form-input
                        type="text"
                        v-model="formDepreciation.residu"
                        />
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <feather-icon
                        icon="CheckIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Umur Ekonomis</span>
                </th>
                <td class="pb-50 text-capitalize">
                    <b-form-input
                        type="text"
                        v-model="formDepreciation.economic"
                        />
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <feather-icon
                        icon="CheckIcon"
                        class="mr-75"
                    />
                    <span class="font-weight-bold">Jenis Custodian</span>
                </th>
                <td class="pb-50 text-capitalize">
                    <b-form-input
                        type="text"
                        v-model="formDepreciation.custodian"
                        />
                </td>
            </tr>
            <tr>
                <th class="pb-50">
                    <span class="font-weight-bold"></span>
                </th>
                <td class="pb-50 text-capitalize">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        class="mt-3"
                        variant="primary"
                        block
                        @click="depreciation"
                        >
                        {{ $t('Create') }}
                    </b-button>  
                </td>
            </tr>
        </table>
    </b-col>          
</template>

<script>
import { BTabs, BTab, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'

export default {
    props: {
        depreciationByDistribution: {
            type: Object,
        },
    },
    components: {
        BCardText,
        BTabs,
        BTab,
        BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            formDepreciation: {
                id: '',
                end_date: '',
                price: '',
                residu: '',
                economic: '',
                custodian: '',
            }
        }
    },
    methods: { 
        depreciation() {
            console.log(this.formDepreciation.id)
            if(this.formDepreciation.id !== undefined && this.formDepreciation.id !== ''){
                moduleApi.updateDepreciation({
                id: this.formDepreciation.id,
                received_date: this.formDepreciation.end_date,
                price: this.formDepreciation.price,
                residu: this.formDepreciation.residu,
                economic: this.formDepreciation.economic,
                custodian: this.formDepreciation.custodian,
                })
                .then(response => {
                    this.formDepreciation.end_date = response.data.received_date
                    this.formDepreciation.price = response.data.price
                    this.formDepreciation.residu = response.data.residu
                    this.formDepreciation.economic = response.data.economic_age
                    this.formDepreciation.custodian = response.data.custodian
                })
                .catch(error => {
                console.log(error)
                })
            } else {
                moduleApi.createDepreciation({
                received_date: this.formDepreciation.end_date,
                price: this.formDepreciation.price,
                residu: this.formDepreciation.residu,
                economic: this.formDepreciation.economic,
                custodian: this.formDepreciation.custodian,
                distribution_id: this.depreciationByDistribution,
                })
                .then(response => {
                    this.formDepreciation.end_date = response.data.received_date
                    this.formDepreciation.price = response.data.price
                    this.formDepreciation.residu = response.data.residu
                    this.formDepreciation.economic = response.data.economic_age
                    this.formDepreciation.custodian = response.data.custodian
                    this.formDepreciation.id = response.data.id
                })
                .catch(error => {
                    console.log(error)
                })
            }
        },
        fetchDataDepreciation() { 
            moduleApi.fetchDataDepreciation(this.depreciationByDistribution)
            .then(response => {
                this.formDepreciation.id = response.data.id
                this.formDepreciation.end_date = response.data.received_date
                this.formDepreciation.price = response.data.price
                this.formDepreciation.residu = response.data.residu
                this.formDepreciation.economic = response.data.economic_age
                this.formDepreciation.custodian = response.data.custodian
            })
            .catch(error => {
            console.log(error)
            })
        }
    },
    watch: {
        depreciationByDistribution: {
            handler(newVal) {
                this.fetchDataDepreciation()
            },
            immediate: true
        }
    }
}
</script>