<template>
  <b-card 
    class="card"
  >
    <b-tabs
      
      justified
    >
      <!-- Tab: General Information -->
      <b-tab active>
        <template #title>
          <feather-icon icon="MonitorIcon" />
          <span class="d-none d-sm-inline">{{ $t('General') }}</span>
        </template>
        <product-tab-general class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Composition 
      <b-tab v-if="status ? '' : 'disabled'"> -->
      <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Composition') }}</span>
        </template>
        <product-tab-composition class="mt-2 pt-75" />
      </b-tab> 

      <!-- Tab: Inventory -->
      <!-- <b-tab>
        <template #title>
          <span class="d-none d-sm-inline">{{ $t('Supplying') }}</span>
        </template>
        <product-tab-inventory class="mt-2 pt-75" />
      </b-tab> -->
 
      <b-tab>
        <template #title>
          <feather-icon icon="RotateCcwIcon" />
          <span class="d-none d-sm-inline">{{ $t('Distribution') }}</span>
        </template>
        <product-tab-distribution class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Setting -->
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" />
          <span class="d-none d-sm-inline">{{ $t('Settings') }}</span>
        </template>
        <product-tab-setting class="mt-2 pt-75" />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import {
  BTab, BTabs, BCard
} from 'bootstrap-vue'
import ProductTabGeneral from './ProductTabGeneral.vue'
import ProductTabComposition from './ProductTabComposition.vue'
import ProductTabInventory from './ProductTabInventory.vue'
import ProductTabDistribution from './ProductTabDistribution.vue'
import ProductTabSetting from './ProductTabSetting.vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  components: {
    BTab,
    BTabs,
    BCard,

    ProductTabGeneral,
    ProductTabComposition,
    ProductTabInventory,
    ProductTabDistribution,
    ProductTabSetting,
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
}
</script>

<style>

</style>
