<template>
  <div>

    <!-- Form -->
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            v-b-modal.modal-composition
          >
            Tambah
          </b-button>
        </b-col>
      </b-row>
      <!-- TABLE -->
      <b-row>              
          <b-card
            no-body
            class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="AlignJustifyIcon"
                  size="18"
                />
                <span class="align-middle ml-50">List</span>
              </b-card-title>
            </b-card-header>
            <b-table
              fixed
              class="mb-0"
              :fields="field" 
              :items="composition"
            >
              
              <template #cell(quantity)="data">
                {{ data.item.quantity }} {{ data.item.unit }}
              </template>
              <template #cell(id)="data">
                  <div class="text-nowrap">
                  <feather-icon
                      v-b-modal.modal-composition
                      :id="`receipt-row-${data.item.id}`"
                      @click="editComposition(data.item.id)"
                      icon="EditIcon"
                      class="cursor-pointer"
                      size="20"
                  />
                  <b-tooltip
                      title="Edit Receipt"
                      class="cursor-pointer"
                      :target="`receipt-row-${data.item.id}`"
                  />

                  <feather-icon
                      :id="`receipt-row-${data.item.id}`"
                      @click="showMsgBoxRemove(data.item.id)"
                      icon="TrashIcon"
                      size="20"
                      class="mx-2 cursor-pointer"
                  />
                  <b-tooltip
                      title="Delete Receipt"
                      class="cursor-pointer"
                      :target="`receipt-row-${data.item.id}`"
                  />
                  </div>
              </template>
            </b-table>
          </b-card>
          <b-col cols="12">
        </b-col>
      </b-row>

      <!-- Form -->
      <b-modal
        id="modal-composition"
        title="Komposisi"
        hide-footer
        @hidden="resetModalComposition"
      >
        <div class="d-block">
          <b-form class="mt-1">
          
          <div role="group">
            <label for="input-live">{{ status_switch }}</label>
            <b-form-checkbox
              v-model="status"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchStatus"
            />
          </div>

          <div role="group">
            <label for="input-live">{{ $t('Form.Product.Product') }}</label>
              <v-select
                v-model="product"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="products"
                
                @input="fetchDataUnit()"
                label="name"
              />
          </div>

          <div role="group">
            <label for="input-live">Kuantitas</label>
              <b-form-input
                id="quantity"
                type="number"
                v-model="quantity"
              />
          </div>

          <div role="group">
            <label for="input-live">{{ $t('Unit') }}</label>
              <v-select
                v-model="unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="units"
                :reduce="(option) => option.title"
                label="title"
              />
          </div>
      </b-form>
          
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-3"
          variant="primary"
          block
          @click="created"
        >
          {{ $t('Create') }}
        </b-button>        
      </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BInputGroup, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BInputGroupAppend, BInputGroupPrepend, 
  BFormTextarea, BModal, BTable, BCard, BCardHeader, BCardTitle, BAvatar, BFormCheckbox, BCardText
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import moduleApi from '@/store/module'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BInputGroup, BFormGroup, 
    flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BModal,
    BTable, BCard, BCardHeader, BCardTitle, BAvatar, BFormCheckbox, BCardText
  },
  data: () => ({
    id: '',
    quantity: '',
    unit: '',
    product: {id: '', name: ''},
    name: '',
    warehouse_insert: null,
    pageLength: 10,
    field: [
      { key: 'name', label: 'Name' },  
      { key: 'quantity', label: 'Quantity' },
      { key: 'id', label: 'Actions' },
    ],
    products: [],
    units: [],
    composition: [],
    warehouse_id: '',
    status: false,
    status_switch: 'Umum',
    warehouse_activity: '',
    composition_obj: {}
  }),
  methods: {
    fetchDataUnit() { 
      moduleApi.fetchDataListUnitsByGroup(this.product.id_unit_group)
        .then(response => {
            this.units = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },
    fetchDataListProduct(idInstansi) { 
      moduleApi.fetchDataListProducs(idInstansi)
        .then(response => {       
            this.products  = response.data.data
        })
        .catch(error => {
          console.log(error.response.data.error)
        })
    },
    fetchDataListComposition(id_product) { 
      moduleApi.fetchDataListComposition(id_product)
        .then(response => {       
            this.composition  = response.data.data
        })
        .catch(error => {
          console.log(error.response.data.error)
        })
    },
    fetchDataCompositionWarehouse(id_product, warehouse_id) { 
      moduleApi.fetchDataCompositionWarehouse(id_product, warehouse_id)
        .then(response => {       
            this.composition.push(...response.data.data)
        })
        .catch(error => {
          console.log(error.response.data.error)
        })
    },
    switchStatus(checked) {
      if(checked){
        this.status_switch = 'Spesifik Konfigurasi'
        this.status = true
        this.warehouse_insert = this.warehouse_id
        this.fetchDataCompositionWarehouse(this.$route.params.id, this.warehouse_id)
      } else {
        this.status_switch = 'Umum'
        this.status = false
        this.warehouse_insert = null
        this.fetchDataListComposition(this.$route.params.id)
      }
    },
    created() { 
      if(this.id === ''){
        moduleApi.createComposition({
          quantity: this.quantity,
          unit: this.unit,
          id_product: this.product.id,
          name: this.product.name,
          product_parent: this.$route.params.id,
          warehouse_id: this.warehouse_insert
        })
        .then(response => {
            this.composition.push(response.data.data)         
            this.quantity = ''
            this.product.id = ''
            this.product.name = ''
            this.unit = ''
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        moduleApi.updateComposition({
          quantity: this.quantity,
          unit: this.unit,
          id_product: this.product.id,
          name: this.product.name,
          id: this.id
        })
        .then(response => {
          this.fetchDataListComposition(this.$route.params.id)
          if(this.warehouse_insert != null){
            this.fetchDataCompositionWarehouse(this.$route.params.id, this.warehouse_id)
          }         
          this.id = ''
          this.quantity = ''
          this.product.id = ''
          this.product.name = ''
          this.unit = ''
        })
        .catch(error => {
          console.log(error)
        })
      }
    },
    editComposition(id) {
      moduleApi.fetchDataCompositionById(id)
        .then( response => {
            this.composition_obj = response.data.data
            this.id = this.composition_obj.id
            this.quantity = this.composition_obj.quantity
            this.product.name = this.composition_obj.name
            this.product.id = this.composition_obj.id_product
            this.unit = this.composition_obj.unit
        })
        .catch( error => {
          console.log(error)
        })
    },
    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.remove(id)
          }
        })
    },
    remove (id) {
      moduleApi.deletedComposition(id)
        .then( response => {
            this.fetchDataListComposition(this.$route.params.id)
          if(this.warehouse_insert != null){
            this.fetchDataCompositionWarehouse(this.$route.params.id, this.warehouse_id)
          }
        })
        .catch( error => {
          console.log(error)
        })
    },
    resetModalComposition () {
      this.quantity = ''
      this.product.id = ''
      this.product.name = ''
      this.unit = ''
    }
  },
  created () {
    if(localStorage.getItem("warehouse") === null){
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Announcement`,
          icon: 'InfoIcon',
          variant: 'warning',
          text: `please specify the determination warehouse`,
        },
      })
    }else{
      this.warehouse_id = localStorage.getItem("warehouse")
    }

    const userData = getUserData()
    this.idInstansi = userData.idInstansi

    this.fetchDataListProduct(this.idInstansi)
    this.fetchDataListComposition(this.$route.params.id)
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
