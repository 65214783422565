const FormData = require('form-data')

export default class Purchase {

  _fetch(options = {}) {
    axios.request(options)
    .then((response) => {
      console.log(response);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  async fetchDataPurchase(purchaseOrder) { 
    var formdata = new FormData()
    formdata.append('transaction', purchaseOrder);

  //   const config = {
  //     method: 'POST',
  //     redirect: 'follow',
  //     body: formdata,
  //   };

  //   fetch("http://rizki-api-eproc.mutucertification.com/api/v1.0/procurement/detailsAsset", config)
  // .then(response => response.text())
  // .then(result => console.log(result))
  // .catch(error => console.log('error', error));

//   let config = {
//   method: 'POST',
//   maxBodyLength: Infinity,
//   url: 'http://rizki-api-eproc.mutucertification.com/api/v1.0/procurement/detailsAsset',
//   headers: {
//     'Content-Type': 'multipart/form-data'
//   },
//   data : formdata,
//   withCredentials: true
// };

// axios.request(config)
// .then((response) => {
//   console.log(JSON.stringify(response.data));
// })
// .catch((error) => {
//   console.log(error);
// });
    return {}
}

}