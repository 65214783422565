<template>
  <b-tabs
          vertical
          nav-wrapper-class="nav-vertical"
        >
          <b-tab
            active
            :title="$t('Units')"
          >
            <b-card-body>
              <b-row class="my-2">        
                  <b-col
                    cols="12"
                    md="5"
                  >   
                    <b-form @submit.prevent="created">           
                      <b-form-group>
               
                        <v-select
                          v-model="unit_group"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="items"
                          :placeholder="$t('Units')"
                          @input="fetchDataListUnit()"
                          :reduce="(option) => option.id"
                          label="title"
                        />
                      </b-form-group>
                    </b-form>
                  </b-col>
                  <b-col
                    cols="12"
                    md="7"
                  >                 
                    <b-card-text>
                      <b-table responsive="sm" :fields="fields" :items="units">
                        <!-- Column: Actions -->
                      </b-table>
                    </b-card-text>
                    <b-card-text>
                      {{ $t('Setting_unit') }} 
                    </b-card-text>
                  </b-col>
                  <b-col>
                    <div class="d-flex flex-column flex-sm-row pt-1">             
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                        @click="created"
                      >
                        <span>{{ $t('Apply') }}</span>
                      </b-button>
                    </div>
                  </b-col>        
              </b-row>
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('Stock')">
            <b-card-body>
              <b-row class="my-2">
                <b-col
                  cols="12"
                  md="5"
                >   
                  <b-form @submit.prevent=""> 
                    <b-form-group
                     label="Min"
                    >
                      <b-form-input
                        v-model="min"
                        id="mc-last-name"
                      />
                    </b-form-group>
                    <b-form-group
                     label="Max"
                    >
                      <b-form-input
                        v-model="max"
                        id="mc-last-name"
                      />
                    </b-form-group>
                  </b-form>
                  <div class="d-flex flex-column flex-sm-row pt-1">             
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click=""
                    >
                      <span>{{ $t('Apply') }}</span>
                    </b-button>
                  </div>
                </b-col>       
              </b-row>
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('Attributes')">
            <b-card-body>
              <attribute
                :productId="productId"
              />
            </b-card-body>
          </b-tab>

          <b-tab :title="$t('Notification')">
            <b-card-body>
              <b-row class="my-2">
                <b-col
                  cols="12"
                  md="5"
                >   
                  <b-form @submit.prevent="">           
                    <b-card-text class="mb-0">
                      {{ status_switch }}
                      </b-card-text>
                        <b-form-checkbox
                          v-model="status"
                          class="custom-control-primary"
                          name="check-button"
                          switch
                          @change="switchStatus"
                        />
                  </b-form>
                  <div class="d-flex flex-column flex-sm-row pt-1">             
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                      @click=""
                    >
                      <span>{{ $t('Apply') }}</span>
                    </b-button>
                  </div>
                </b-col>       
              </b-row>
            </b-card-body>
          </b-tab>
        </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText, BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable, BFormCheckbox} from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Attribute from './setting/attribute.vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCardText,
    BTabs,
    BTab,
    vSelect,
    BCardBody, BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BTable,
    BFormCheckbox,
    Attribute
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      instansi_id: '',
      productId: this.$route.params.id,
      formAttribute: [
        { id_external: '', title: '' },
      ],
      min: '',
      max: '',
      status_switch: 'TIdak Aktif',
      items: [],
      unit_group: '',
      units: [],
      fields: [
        { key: 'title', label: 'Satuan' },
      ],
      product: {}
    }
  },
  methods: {
    fetchDataListWarehouse(id) { 
      moduleApi.fetchDataProducsById(id)
        .then(response => {
            this.product  = response.data.data
            this.unit_group = this.product.id_unit_group
            this.id_external = this.product.id_external
            this.fetchDataListUnit()
        })
        .catch(error => {
          console.log(error)
        })
    },

    fetchDataListGroupUnits() { 
      moduleApi.fetchDataListGroupUnits()
        .then(response => {
            this.items  = response.data.data
            this.fetchDataListGroupUnitsByInstansi()
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchDataListGroupUnitsByInstansi() { 
      moduleApi.fetchDataListGroupUnitsByInstansi(this.instansi_id)
        .then(response => {
            this.items.push(...response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchDataListUnit() { 
      moduleApi.fetchDataListUnitsByGroup(this.unit_group)
        .then(response => {
            this.units = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    switchStatus(checked) {
      if(checked){
        this.status_switch = 'Aktif'
      } else {
        this.status_switch = 'TIdak Aktif'
      }
    },
    created() { 
      const formData = new FormData()
      formData.append('id', this.$route.params.id)
      formData.append('id_unit_group', this.unit_group)
      formData.append('updated_at', new Date().toISOString())

      moduleApi.updateProduct(formData)
        .then(response => {
          this.fetchDataListWarehouse(this.$route.params.id)
          this.fetchDataListUnit()
        })
        .catch(error => {
          console.log(error)
        })

        // moduleApi.transactionsInsert({
        //   product_id: this.product.id,
        //   product_name: this.product.name,
        //   unit_id: this.units[0].id,
        //   unit_title: this.units[0].title,
        //   warehouse: localStorage.getItem("warehouse")
        // })
        // .then(response => {
        //   console.log(response)
        // })
        // .catch(error => {
        //   console.log(error)
        // })
    },
    updateExternal() {
      const formData = new FormData()
      formData.append('id', this.$route.params.id)
      formData.append('id_external', this.id_external)
      formData.append('updated_at', new Date().toISOString())

      moduleApi.updateProduct(formData)
        .then(response => {
          this.fetchDataListWarehouse(this.$route.params.id)
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  created () {
    const userData = getUserData()
    this.instansi_id = userData.idInstansi
    this.fetchDataListWarehouse(this.$route.params.id)
    this.fetchDataListGroupUnits()  
  },
  setup(props) {
   const { t } = useI18nUtils()

    return {
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
