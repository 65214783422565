<template>
  <div>
    <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            v-b-modal.modal-distribusi
          >
            Tambah
          </b-button>
        </b-col>
      </b-row>
    <!-- PERMISSION TABLE -->
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="AlignJustifyIcon"
            size="18"
          />
          <span class="align-middle ml-50">List</span>
        </b-card-title>
      </b-card-header>
      <b-table
        fixed
        class="mb-0"
        :fields="field"
        :items="distribution"
      >
        <template #cell(quantity)="data">
          {{ data.item.quantity }} {{ data.item.unit }}
        </template>
        <template #cell(control_date)="data">
          {{ new Date(data.item.control_date).toString().slice(4,15) }}
        </template>
        <template #cell(id)="data">
            <div class="text-nowrap">
              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-success"
                  class="btn-icon rounded-circle"
                  @click="sendkey(data.item.label_qr_code, data.item)">
                  <b-avatar
                      v-b-modal.modal-center
                      :src="`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${url}/snap/${data.item.label_qr_code}?choe=UTF-8`"
                      size="30px"
                      rounded
                    />
              </b-button>

            <feather-icon
                v-b-modal.modal-purchase
                @click="actionPurchase(data.item.id, data.item.purchase_order)"
                icon="ExternalLinkIcon"
                class="cursor-pointer"
                size="20"
            />

            <feather-icon
                v-b-modal.modal-distribusi
                @click="update(data.item.id)"
                icon="EditIcon"
                class="mx-1 cursor-pointer"
                size="20"
            />

            <feather-icon
                @click="showMsgBoxRemove(data.item.id)"
                icon="TrashIcon"
                size="20"
                class="cursor-pointer"
            />
            </div>
        </template>
      </b-table>

      <b-modal
        id="modal-purchase"
        title="Detail"
        size="lg"
        hide-footer
      >
        <details-purchase 
          :distributionId="distributionId"
          :purchaseOrder="purchaseOrder"
        />
      </b-modal>

      <b-modal
        id="modal-distribusi"
        title="Distribusi"
        hide-footer
        @hidden="resetModalDistribution"
        ref="modal-distribusi"
      >
        <div class="d-block">
          <b-form class="mt-1">
          <!-- Quantity -->
          <div role="group">
            <label for="input-live">{{ $t('Form.Distribution.Quantity') }}</label>
            <b-form-input
              id="quantity"
              type="number"
              v-model="formDistribution.quantity"
            />
          </div>

          <div role="group">
            <label for="input-live">{{ $t('Unit') }}</label>
              <v-select
                v-model="formDistribution.unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="units"
                :reduce="(option) => option.title"
                label="title"
              />
          </div>

          <div role="group">
            <label for="input-live">{{ $t('Form.Distribution.Unique') }}</label>
            <b-form-input
              id="unique"
              v-model="formDistribution.unique"
            />
          </div>

          <b-row class="my-2">
            <b-col cols="12" md="12">
              <div role="group"> 
                <label for="input-live">{{ $t('Form.Distribution.Responsible') }}</label>
                <b-form-input
                  id="quantity"
                  type="text"
                  v-model="formDistribution.responsible"
                />
              </div>
            </b-col>
            <!-- <b-col cols="12" md="2">
              <div role="group">
                <label for="input-live"></label>
                <b-button
                variant="outline-primary">
                <feather-icon
                    icon="ExternalLinkIcon"
                    class="cursor-pointer"
                    v-b-modal.modal-responsible
                    variant="outline-primary"
                />
              </b-button>
              </div>
            </b-col> -->
          </b-row>

          <b-row class="my-2">
            <b-col cols="12" md="6">
              <div role="group"> 
                <label for="input-live">{{ $t('Form.Distribution.ControlDate') }}</label>
                  <flat-pickr
                    class="form-control"
                    :config="{ dateFormat: 'Y-m-d'}"
                    placeholder="YYYY-MM-DD"
                    v-model="formDistribution.control"
                  />
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div role="group">
                <label for="input-live">{{ $t('Form.Distribution.Title') }}</label>
                <b-form-input
                  type="text"
                  v-model="formDistribution.title"
                />
                </div>
            </b-col>
          </b-row> 

          <div role="group">
            <label for="input-live">{{ $t('Form.Distribution.Pic') }}</label>
              <b-form-input
                id="pic"
                placeholder="John"
                v-model="formDistribution.pic"
              />
          </div>

          <div role="group">
            <label for="input-live">{{ $t('Form.Distribution.Description') }}</label>
              <b-form-textarea
                id="notes"
                placeholder="Deskripsi"
                rows="2"
                v-model="formDistribution.description"
              />
          </div>
          <!-- <b-modal
            id="modal-responsible"
            ref="room-modal"
            cancel-variant="outline-secondary"
            ok-title="Simpan"
            @ok="handleRooms"
            cancel-title="Keluar"
            centered
            title="Ruangan/Penanggung Jawab/Lokasi"
          >
            <b-form>
              <b-form-group>
                <label for="title">{{ $t('Form.Responsible.Title') }}:</label>
                <b-form-input
                  v-model="formRoom.title"
                />
              </b-form-group>
              <b-form-group>
                <label for="description">{{ $t('Form.Responsible.Description') }}:</label>
                <b-form-input
                  v-model="formRoom.description"
                />
              </b-form-group>
            </b-form>
          </b-modal>        -->
      </b-form>
          
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-3"
          variant="primary"
          block
          @click="created"
        >
          {{ $t('Create') }}
        </b-button>        
      </b-modal>

    <b-modal
      id="modal-center"
      title="QR-Code"
      size="lg"
      hide-footer
    >
      <div> 
        <div class="print-container" style="text-align: center;">
            <b-avatar
              :src="`https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${url}/snap/${label_qr_code}?choe=UTF-8`"
              size="350px"
              rounded
            />
          <div class="details" style="text-align: left; max-width: 630px; margin: 0 auto; margin-top: -40px;">
            <h2>{{ product_detail.product_name }}</h2>
            <h5>{{ warehouse_name }} - {{ product_detail.responsible_name }}</h5>
            <h5>{{ product_detail.merk }} - {{ product_detail.types }}</h5>
            <h5>{{ product_detail.series }}</h5>
          </div>
        </div>
      </div> 
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-3"
        variant="primary"
        block
        @click="printPage"
      >
        Print
      </b-button>
    </b-modal>

    <div hidden>
      <div class="print-container" id="printableArea" style="text-align: center;">
          <b-avatar
            :src="`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${url}/snap/${label_qr_code}?choe=UTF-8`"
            size="300px"
            rounded
          />
        <!-- <div class="details" style="text-align: left; max-width: 630px; margin: 0 auto; margin-top: -10px;"> -->
        <div class="details" style="margin: 0 auto; margin-top: -10px;">
          <h2>{{ product_detail.product_name }}</h2>
          <h5 style="margin-top: -23px;">{{ warehouse_name }} - {{ product_detail.responsible_name }}</h5>
          <h5 style="margin-top: -23px;">{{ product_detail.merk }} - {{ product_detail.types }}</h5>
          <h5 style="margin-top: -23px;">{{ product_detail.series }}</h5>
        </div>
      </div>
    </div>
    
    </b-card>
  </div>
</template> 

<script>
import {
  BRow, BCol, BForm, BInputGroup, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BInputGroupAppend, BInputGroupPrepend, 
  BFormTextarea, BModal, BTable, BCard, BCardHeader, BCardTitle, BAvatar, BFormTags, VBModal, BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { getUserData } from '@/auth/utils'
import moduleApi from '@/store/module'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import DetailsPurchase from './distribution/Details.vue'


export default {
  components: {
    BRow, BCol, BForm, BInputGroup, BFormGroup, 
    flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BModal,
    BTable, BCard, BCardHeader, BCardTitle, BAvatar, BFormTags, BFormFile,
    DetailsPurchase
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data: () => ({
    localStorageWarehouse: JSON.parse(localStorage.getItem("warehouse")),
    pageLength: 10,
    field: [
      { key: 'quantity', label: 'Kuantitas' },
      { key: 'series', label: 'Seri' },
      { key: 'responsible_name', label: 'Ruangan/Penanggung Jawab/Lokasi' },       
      { key: 'control_date', label: 'Kontrol Tanggal' },
      { key: 'description', label: 'Deskripsi' },
      { key: 'id', label: 'Tindakan' }, 
    ],
    responsibelOptions: [],
    idInstansi: '',
    distribution: [],
    product: [],
    units: [],
    formDistribution: {
      id: '',
      quantity: '',
      unit: '',
      unique: '',
      responsible: '',
      pic: '',
      control: new Date().toISOString(),
      title: '',
      description: '',
    },
    formRoom: {
      id: '',
      title: '',
      description: ''
    },
    label_qr_code: '',
    warehouse_id: '',
    warehouse_name: '',
    product_detail: {
        name: '',
        merk: '',
        product_name: '',
        warehouse_name: '',
        responsible_name: '',
        series: ''
      },
    url: process.env.VUE_APP_BASE_URL,
    images: require('@/assets/images/logo/logo.png'),
    distributionId: '',
    purchaseOrder:''
  }),
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  methods: {
    sendkey(label_qr_code, data) {
        this.label_qr_code = label_qr_code
        this.warehouse_name = this.localStorageWarehouse.name
        this.product_detail = data
    },
    fetchDataListWarehouse(id) { 
      moduleApi.fetchDataProducsById(id)
        .then(response => {
            this.product  = response.data.data
            this.fetchDataUnit(this.product.id_unit_group)
        })
        .catch(error => {
          console.log(error)
        })
    },

    fetchDataUnit(id_unit_group) { 
      moduleApi.fetchDataListUnitsByGroup(id_unit_group)
        .then(response => {
            this.units = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },

    fetchDataListResponsible(id) {
      moduleApi.fetchDataListResponsible(id)
        .then(response => {
            this.responsibelOptions  = response.data.data          
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },

    fetchDataListDistribution(...params) {
      moduleApi.fetchDataListDistribution(...params)
        .then(response => {
            this.distribution  = response.data            
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },

    handleRooms(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.submitRooms()
    },

    submitRooms() { 
      moduleApi.createRooms({
        title: this.formRoom.title,
        description: this.formRoom.description,
        warehouse_id: this.localStorageWarehouse.id
      })
        .then(response => {
          this.$refs['room-modal'].toggle('#toggle-btn')
          this.fetchDataListResponsible(this.localStorageWarehouse.id)
          this.formRoom.title = ''
          this.formRoom.description = ''
        })
        .catch(error => {
          console.log(error)
        })
    },

    created() { 
      if(this.formDistribution.id === ''){
        moduleApi.createDistribution({
          quantity: this.formDistribution.quantity,
          unit: this.formDistribution.unit,
          unique: this.formDistribution.unique,
          responsible_name: this.formDistribution.responsible,
          pic: this.formDistribution.pic,
          control_date: this.formDistribution.control,
          title: this.formDistribution.title,
          description: this.formDistribution.description,
          id_product: this.$route.params.id,
          warehouse_id: this.localStorageWarehouse.id
        })
        .then(response => {
            this.fetchDataListDistribution({warehouse: this.localStorageWarehouse.id, product: this.$route.params.id})
            this.$refs['modal-distribusi'].toggle('#toggle-btn')
            this.formDistribution.quantity = ''
            this.formDistribution.unit = ''
            this.formDistribution.unique = ''
            this.formDistribution.responsible = ''
            this.formDistribution.pic = '',
            this.formDistribution.control = new Date().toISOString()
            this.formDistribution.title = ''
            this.formDistribution.description = ''
        })
        .catch(error => {
          console.log(error)
        })
      } else {
        moduleApi.updateDistribution({
          id: this.formDistribution.id,
          quantity: this.formDistribution.quantity,
          unit: this.formDistribution.unit,
          unique: this.formDistribution.unique,
          responsible_name: this.formDistribution.responsible,
          pic: this.formDistribution.pic,
          control_date: this.formDistribution.control,
          title: this.formDistribution.title,
          description: this.formDistribution.description,
          id_product: this.$route.params.id,
          warehouse_id: this.localStorageWarehouse.id
        })
        .then(response => {
            this.fetchDataListDistribution({warehouse: this.localStorageWarehouse.id, product: this.$route.params.id})
            this.$refs['modal-distribusi'].toggle('#toggle-btn')
            this.formDistribution.id = ''
            this.formDistribution.quantity = ''
            this.formDistribution.unit = ''
            this.formDistribution.unique = ''
            this.formDistribution.responsible = ''
            this.formDistribution.pic = '',
            this.formDistribution.control = new Date().toISOString()
            this.formDistribution.title = ''
            this.formDistribution.description = ''
        })
        .catch(error => {
          console.log(error)
        })
      }
    },

    update (id) {
      moduleApi.fetchObjDistribution({id: id})
      .then(response => { 
          let data  = response.data.data[0]
          this.formDistribution.id = data.id
          this.formDistribution.quantity = data.quantity
          this.formDistribution.unit = data.unit
          this.formDistribution.unique = data.series
          this.formDistribution.responsible = data.responsible_name // {id: data.responsible_id, title: data.responsible_name}
          this.formDistribution.pic = data.pic
          this.formDistribution.control = data.control_date
          this.formDistribution.title = data.title
          this.formDistribution.description = data.description           
      })
      .catch(error => {
        this.$refs.loginForm.setErrors(error.response.data.error)
      })
    },

    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.remove(id)
          }
        })
    },
    remove (id) {
      moduleApi.deletedDistribution({
        id: id,
        deleted_at: new Date().toISOString()
      })
      .then( response => {
        this.fetchDataListDistribution({warehouse: this.localStorageWarehouse.id, product: this.$route.params.id})
      })
      .catch( error => {
        console.log(error)
      })
    },
    resetModalDistribution () {
      this.formDistribution.id = ''
      this.formDistribution.quantity = ''
      this.formDistribution.unit = ''
      this.formDistribution.unique = ''
      this.formDistribution.responsible = ''
      this.formDistribution.control = new Date().toISOString()
      this.formDistribution.title = ''
      this.formDistribution.description = ''
    },
    handleFileUpload (){

    },
    printPage() {
      const printableArea = document.getElementById('printableArea');
      const printWindow = window.open('', '_blank');
      printWindow.document.write(printableArea.innerHTML);
      printWindow.document.close();
    },
    actionPurchase(distributionId, purchaseOrder) {
      this.distributionId = distributionId,
      this.purchaseOrder = purchaseOrder
      this.$refs['modal-purchase'].show()
    },
  },
  created () {
    const userData = getUserData()
    this.idInstansi = userData.idInstansi
    this.fetchDataListWarehouse(this.$route.params.id)
    this.fetchDataListDistribution({warehouse: this.localStorageWarehouse.id, product: this.$route.params.id})
    this.fetchDataListResponsible(this.localStorageWarehouse.id)
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.print-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.details {
  text-align: left;
}
</style>
