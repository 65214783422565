<template>
    <b-col cols="12" md="12">
                    <table class="mt-2 mt-xl-0 w-100">
                        <tr>
                            <td class="pb-50">
                                <b-form-file
                                    v-model="images_file"
                                    id="files"
                                    name="files"
                                    @change="handleFileUpload( $event )"
                                    placeholder="Gambar Aset"
                                    drop-placeholder="Drop file here..."
                                    accept=".jpeg, .png, .gif, .jpg"
                                />
                            </td>
                        </tr>
                    </table>
                    <b-table responsive="sm" :fields="fields" :items="items">
                        <!-- Column: Actions -->
                        <template #cell(images_link)="data">
                            <div class="text-nowrap">
                                <b-avatar
                                    :src="data.item.images_link"
                                    class="mx-1"
                                />
                            </div>
                        </template>
                        <template #cell(created_at)="data">
                            <div class="text-nowrap">
                                {{ new Date(data.item.created_at).toString().slice(4,15) }}
                            </div>
                        </template>
                        <template #cell(id)="data">
                            <div class="text-nowrap">
                            <feather-icon
                                @click="showMsgBoxRemove(data.item.id)"
                                icon="TrashIcon"
                                size="20"
                                class="mx-2 cursor-pointer"
                            />
                            </div>
                        </template>
                    </b-table>
    </b-col>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BForm, BTable, BAvatar,
    BFormFile
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moduleApi from '@/store/module'

export default {
    props: {
        list_images: {
            type: String,
        },
    },
    components: {
        BCol, BFormGroup, BFormInput, BForm, BTable,
        BAvatar,
        BFormFile,

    },
    directives: {
        Ripple,
    },
    data() {
        return {
            items: [],
            images_file: '',
            files: null,
            fields: [
            { key: 'images_link', label: 'Gambar' },
            { key: 'created_at', label: 'Pengambilan' },
            { key: 'id', label: 'Tindakan' },
        ],
        }
    },
    methods: { 
        renderImages() {           
            moduleApi.fetchImages(this.list_images)
            .then(response => {               
                this.items = response.data
            })
            .catch(error => {
                console.log(error)
            })
        },
        handleFileUpload (event) {
            this.files = event.target.files ? event.target.files[0] : null;
            
            const formData = new FormData()
                formData.append('file', this.files)
                formData.append('distribution_id', this.list_images)
                moduleApi.createDistributionImages(formData)
                .then(response => {
                    this.images_file = ''
                    this.renderImages()
                })
                .catch(error => {
                    console.log('error upload')
                })
        },

        showMsgBoxRemove(id) {
            this.$bvModal
                .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
                title: 'Konfirmasi',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Ya',
                cancelTitle: 'Tidak',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            })
            .then(value => {
                if(value){
                    this.remove(id)
                }
            })
        },

        remove (id) {
            moduleApi.deletedDistributionImages(id)
            .then( response => {
                this.renderImages()
            })
            .catch( error => {
                console.log(error)
            })
        },
    },
    watch: {
        list_images: {
            handler(newVal) {
                this.renderImages()
            },
        immediate: true
        }
    }
}
</script>