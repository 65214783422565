<template>
  <b-card 
    class="card"
  >
    <b-form @submit.prevent="created">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="4"
          class="align-center justify-center"
        >
        <validation-observer ref="general">
          <b-form-group
            label="Nama"
            label-for="productname"
          >
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >  
              <b-form-input
                v-model="name"
                id="name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        
          <b-form-group
            label="Merek"
            label-for="productmerk"
          >
            <validation-provider
              #default="{ errors }"
              name="merk"
              rules="required"
            >
              <b-form-input
                v-model="merk"
                id="merk"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Tipe"
            label-for="productType"
          >
            <validation-provider
              #default="{ errors }"
              name="type"
              rules="required"
            >
              <b-form-input
                v-model="productType"
                id="type"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Kode"
            label-for="productcode"
          >
            <b-form-input
              v-model="code"
              id="productcode"
            />
          </b-form-group>
       
          <b-form-radio-group
            v-model="criteria"
            :options="options"
            class="demo-inline-spacing mb-1"
            name="radio-inline"
          />

          <b-form-group
            label="Kategori"
            label-for="productcategory"
          >
            <v-select
              v-model="category"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              id="productcategory"
              :options="productcategory"
              :reduce="(option) => option.id"
              label="title"
            />
          </b-form-group>

          <b-form-group
            label="Tag"
            label-for="producttags"
          >
            <b-form-tags
              v-model="tags"
              input-id="tags-separators"
              separator=","
              placeholder="Masuk tag baru"
              no-add-on-enter
              class="mb-2"
            />
          </b-form-group>

          <label for="textarea-default">Catatan</label>
          <b-form-textarea
            v-model="notes"
            placeholder="Catatan"
            rows="3"
          />

          <b-form-group
            label=""
          >
            <b-media class="mt-1">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src=images
                  size="90px"
                  rounded
                />
              </template>
              
              <div class="d-flex flex-wrap">
                <input
                    ref="refInputEl"
                    type="file"
                    accept=".jpg, .png, .gif, .jpeg"
                    @change="handleFileUpload( $event )"      
                  >
              </div>
            </b-media>
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-1"
            variant="primary"
            block
            
            type="submit"
          >
            {{ $t('Submit') }}
        </b-button>
      </validation-observer>
        </b-col>
      </b-row>
   </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BMedia, BFormFile, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormRadioGroup, BFormTags, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moduleApi from '@/store/module'
import { getUserData } from '@/auth/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormRadioGroup,
    vSelect,
    BFormTags,
    BFormTextarea,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    required
  },
  data: () => ({
    id: '',
    name: '',
    merk: '',
    code: '',   
    criteria: '', 
    productType: '',
    category: {},
    tags: [],
    notes: '',   
    selected: 'first',
    options: [
      { text: 'Bisa Dijual', value: '1' },
      { text: 'Bisa Dibeli', value: '2' },
    ], 
    productcategory: [],
    file: null,
    product: [],
    idInstansi: '',
    images: require('@/assets/images/logo/logo.png')
  }),
  setup(props) {
  const { t } = useI18nUtils()

    return {
      t,
    }
  },
  methods: {
    setUserDataInstansi() {
      const userData = getUserData()
      if(userData.idInstansi == null) {
        this.idInstansi = localStorage.getItem("instansi_id");
      } else {
        this.idInstansi =  userData.idInstansi
      }
    },

    fetchDataListProduct(id) { 
      moduleApi.fetchDataProducsById(id)
        .then(response => {
            this.product  = response.data.data
            this.id  = this.product.id
            this.name = this.product.name
            this.merk = this.product.merk
            this.code = this.product.code
            this.criteria = this.product.trade
            this.productType = this.product.types
            this.category = this.product.category
            this.tags = this.product.tags.split(",")
            this.images = (this.product.images? this.product.images : this.images)
            this.notes = this.product.note
        })
        .catch(error => {
          console.log(error)
        })
    },

    fetchDataListCategories() { 
      moduleApi.fetchDataCategories(this.idInstansi)
      .then(response => {
          this.productcategory  = response.data.payload
      })
      .catch(error => {
        console.log(error)
      })
    },

    handleFileUpload (event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },

    created() { 
      this.$refs.general.validate().then(success => {
        if (this.id == 'null') {        
          const formData = new FormData()
          formData.append('file', this.file)
          formData.append('name', this.name)
          formData.append('merk', this.merk)
          formData.append('code', this.code)
          formData.append('criteria', this.criteria)
          formData.append('productType', this.productType)
          formData.append('category', this.category)
          formData.append('tags', this.tags)
          formData.append('note', this.notes)
          formData.append('id_instansi', this.idInstansi)

          moduleApi.createProduct(formData)
            .then(response => {
              this.id = response.data.data.id
              this.$router.replace({name: 'apps-product-create', params: {id: `${response.data.data.id}`}})
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Pembaritahuan`,
                  icon: 'InfoIcon',
                  variant: 'success',
                  text: `Eksekusi Proses Berhasil`,
                },
              })
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          const formData = new FormData()
          formData.append('id', this.$route.params.id)
          formData.append('file', this.file)
          formData.append('name', this.name)
          formData.append('merk', this.merk)
          formData.append('code', this.code)
          formData.append('trade', this.criteria)
          formData.append('types', this.productType)
          formData.append('category', this.category)
          formData.append('tags', this.tags)
          formData.append('note', this.notes)
          formData.append('id_instansi', this.idInstansi)

          moduleApi.updateProduct(formData)
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Pembaritahuan`,
                  icon: 'InfoIcon',
                  variant: 'success',
                  text: `Produk berhasil Diperbaharui`,
                },
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    },
  },
  created () {
    this.setUserDataInstansi()
    this.fetchDataListProduct(this.$route.params.id)
    this.fetchDataListCategories()
    this.id = this.$route.params.id
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
